import { container, primaryColor } from "assets/jss/material-dashboard-react";
import teal from '@material-ui/core/colors/teal'

const signupPageStyle = theme => ({
	iconError: {
		color: "red"
	},
	forgotPass: {
		fontSize: "0.875em",
		margin: 8,
		marginBottom: 12
	},
	loginButton: {
		fontSize: "12px",
		fontWeight: "400",
	},
	loader: {
		marginRight: 'auto',
		marginLeft: 'auto',
		color: primaryColor
	},
	errorLabel: {
		'&$errorFocused': {
			color: "red"
		}
	},
	errorFocused: {
	},
	errorUnderline: {
		'&:after': {
			borderBottomColor: "red",
		},
	},
	label: {
		'&$focused': {
			color: teal[500]
		},
	},
	focused: {
	},
	underline: {
		'&:after': {
			borderBottomColor: teal[500],
		},
	},


	container: {
		...container,
		zIndex: "2",
		position: "relative",
		// paddingTop: "20vh",
		[theme.breakpoints.up("lg")]: {
			paddingTop: "25vh"
		},
		[theme.breakpoints.down("md")]: {
			paddingTop: "15vh"
		},
		color: "#FFFFFF"
	},
	cardHidden: {
		opacity: "0",
		transform: "translate3d(0, -60px, 0)"
	},
	pageHeader: {
		minHeight: "100vh",
		height: "auto",
		display: "inherit",
		position: "relative",
		margin: "0",
		padding: "0",
		border: "0",
		alignItems: "center",
		"&:before": {
			background: "rgba(0, 0, 0, 0.5)"
		},
		"&:before,&:after": {
			position: "absolute",
			zIndex: "1",
			width: "100%",
			height: "100%",
			display: "block",
			left: "0",
			top: "0",
			content: '""'
		}
	},
	form: {
		margin: "0"
	},
	cardHeader: {
		width: "auto",
		textAlign: "center",
		marginLeft: "20px",
		marginRight: "20px",
		marginTop: "-40px",
		padding: "20px 0",
		marginBottom: "15px",
	},
	socialIcons: {
		maxWidth: "24px",
		marginTop: "0",
		width: "100%",
		transform: "none",
		left: "0",
		top: "0",
		height: "100%",
		lineHeight: "41px",
		fontSize: "20px"
	},
	divider: {
		marginTop: "30px",
		marginBottom: "0px",
		textAlign: "center"
	},
	cardFooter: {
		paddingTop: "0rem",
		border: "0",
		borderRadius: "6px",
		justifyContent: "center !important"
	},
	socialLine: {
		marginTop: "1rem",
		textAlign: "center",
		padding: "0",
	},
	inputIconsColor: {
		color: "#495057",
	}
});

export default signupPageStyle;
